import { createApi } from "@reduxjs/toolkit/query/react";
import { mutations, queries } from "./apiDetails";
import { transformErrorResponse } from "./handleErrors";
import customFetchBase from "./baseQuery";
import { tags } from "./constants";

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: customFetchBase,
  tagTypes: Object.keys(tags),
  endpoints: (build) => {
    const endPoints = {};

    Object.keys(mutations).forEach((key) => {
      const apiDetails = mutations[key];

      endPoints[key] = build.mutation({
        query: ({params , body}) => {
          return {
            url: apiDetails.getUrl(params),
            method: apiDetails.method,
            body,
          };
        },
        invalidatesTags: apiDetails.invalidatesTags,
        transformResponse: (response, meta, arg) =>
          apiDetails.transformResponse(response),
        transformErrorResponse: (response, meta, arg) =>
          apiDetails.transformErrorResponse
            ? apiDetails.transformErrorResponse(response)
            : transformErrorResponse(response),
      });
    });

    Object.keys(queries).forEach((key) => {
      const apiDetails = queries[key];
      endPoints[key] = build.query({
        query: (params) => ({
          url: apiDetails.getUrl(params),
          method: apiDetails.method,
        }),
        providesTags: apiDetails.providesTags,
        transformResponse: (response, meta, arg) =>
          apiDetails.transformResponse(response),
        transformErrorResponse: (response, meta, arg) =>
          transformErrorResponse(response),
      });
    });

    return endPoints;
  },
});

export const {
  useDomoQuery,
  useMyJourneysQuery,
  useGenerateCampaignTemplateReportMutation,
  useUpdateCampaignTemplateStatusMutation,
  useMarkAsDefaultCampaignMutation,
  useAddSuperAdminTouchpointMutation,
  useGetSendersQuery,
  useGetTouchPointsQuery,
  useGetTouchPointByIdMutation,
  useAddJourneyMutation,
  useGetJourneyTemplatesQuery,
  useGetCampaignTemplateByIdQuery,
  useGetJourneyCategoryQuery,
  useUpdateSuperAdminTouchpointMutation,
  useGetTouchPointsListQuery,
  useEditJourneyMutation,
  useUpdateSuperAdminTouchpointStatusMutation,
  useGetSuperAdminJourneyCategoryQuery,
  useAddSuperAdminJourneyCategoryMutation,
  useUpdateSuperAdminJourneyCategoryMutation,
  useGetMeetingLocationsQuery,
  useGetJourneyCategoryByIdMutation,
  useGetAdminJourneyTemplatesQuery,
} = apiSlice;
