export const isTopCampaignTemplatesEnabled = false;
export const statusChangeWarningMessage =
  "Are you sure you want to change the status? Once changed, status change cannot be reverted";
export const ROLES = {
  RECRUITER_MANAGER: "Recruiter Manager",
  RECRUITER: "Recruiter",
  SITE_LEADER: "Site Leader",
  CAMPAIGN_ADMIN: "Campaign Admin",
  NEWSFEED_ADMIN: "Newsfeed Admin",
  LEADERSHIP: "Leadership",
  COMPANY_ADMIN: "Company Admin",
  TALENT_ACQUISITION_HEAD: "Talent Acquisition Head",
  SUPER_ADMIN: "Super Admin",
  HIRING_MANAGER: "Hiring Manager",
  REPORT_ADMIN: "Report Admin",
  HR_OPS: "HR Ops",
  TA_DIRECTOR: "TA Director",
  SUPER_ADMIN_CAMPAIGN_MANAGER: "Super Admin Campaign Manager"
};
export const CANDIDATE_ROLE = "Candidate";
export const userErrorMessage = "Something went wrong";
export const magicLinkLoginNotificationChannel = "magicLinkLoginNotificationChannel"
export const candidateStatusList = [
  { value: "Offer Accepted", label: "Offer Accepted" },
  { value: "Offer Revoked", label: "Offer Revoked" },
  { value: "Offer Rejected", label: "Offer Rejected" },
  { value: "Joined", label: "Joined" },
  { value: "No Show", label: "No Show" },
  { value: "On hold", label: "On hold" },
  { value: "Deactivated", label: "Deactivated" },
];
export const iframeRegex = /^<iframe[\s\S]*<\/iframe>$/;
export const domoUrlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

// File sizes in mb
export const fileSizes = {
  'dataFile': 25,
  'image': 1
}
export const imageAcceptParameters = "image/png,image/jpg,image/jpeg,image/svg"
export const fileNameRegex = /^[a-zA-Z0-9_-]+\.[a-zA-Z0-9]+$/;
export const candidateStatus = [
  "Offer Accepted",
  "Joined",
  "Offer Revoked",
  "Offer Rejected",
  "On hold",
  "Deactivated",
  "No Show",
];
export const companyAdminPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/campaign-templates",
  "/[slug]/admin/journey-settings/my-journey",
  "/[slug]/admin/news-feeds",
  "/[slug]/admin/html-templates",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
  "/[slug]/admin/users",
  "/[slug]/admin/themes",
  "/[slug]/admin/settings",
  "/[slug]/admin/auditlogs",
]
export const recruiterPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
  "/[slug]/admin/auditlogs",
]
export const recruiterManagerPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
  "/[slug]/admin/auditlogs",
]
export const hiringManagerPaths = [
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/quiz",
  "/[slug]/admin/auditlogs",
]
export const reportAdminPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
]
export const campaignAdminPaths = [
  // Need to remove below 2 lines if no issue in templatization prod & open the comments if old feature needs to be revoked
  // "/[slug]/admin/campaign-templates",
  // "/[slug]/admin/html-templates",
  "/[slug]/admin/journey-settings/my-journey",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
]
export const newsfeedAdminPaths = [
  "/[slug]/admin/news-feeds",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
]
export const talentAcquisitionHeadPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/survey-templates",
]
export const leadershipPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/campaign-templates",
  "/[slug]/admin/news-feeds",
  "/[slug]/admin/survey-templates",
  "/[slug]/admin/quiz",
  "/[slug]/admin/users",
  "/[slug]/admin/themes",
  "/[slug]/admin/settings",
  "/[slug]/admin/auditlogs",
]
export const hrOpsPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/quiz",
  "/[slug]/admin/auditlogs",
  "/[slug]/admin/auditlogs",
]
export const siteLeaderPaths = [
  "/[slug]/admin/quiz",
]
export const taDirectorPaths = [
  "/[slug]/admin/dashboard",
  "/[slug]/admin/mytask",
  "/[slug]/admin/candidates",
  "/[slug]/admin/positions",
  "/[slug]/admin/quiz",
  "/[slug]/admin/auditlogs",
]
export const superAdminCampaignManagerPaths = [
  "/superadmin/journey/touchpoints",
  "/superadmin/journey/templates",
  "/superadmin/journey/categories",
]
export const getApiRefetchTime = 1000 * 60 * 10; // 5 minutes
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const sessionExpiryTimeInSec = 3600;
export const mandatoryPlaceholders = [
  "{{{CANDIDATE_NAME}}}",
  "{{{POSITION_TITLE}}}",
  "{{{DOJ}}}",
  "{{{COMPANY_NAME}}}",
  "{{{RECRUITER_NAME}}}",
  "{{{CANDIDATE_ADDRESS}}}",
  "{{{TOUCHPOINT_TITLE}}}",
  "{{{CANDIDATE_URL}}}",
  "{{{CANDIDATE_NUMBER}}}",
  "{{{CANDIDATE_EMAIL}}}",
  "{{{CANDIDATE_TP_URL}}}",
  "{{{HIRING_MANAGER_NAME}}}",
  "{{{COMPANY_ADDRESS}}}",
  "{{{MEETING_TYPE}}}",
  "{{{MEETING_PERSON}}}",
  "{{{MEETING_LINK}}}",
  "{{{MEETING_LOCATION}}}",
  "{{{MEETING_DATE}}}",
  "{{{GOOGLE_MAPS_LINK}}}",
  "{{{DISPATCH_DATE}}}",
  "{{{DISPATCH_ADDRESS}}}",
  "{{{POINT_OF_CONTACT}}}",
];

export const quizComingSoonMessage = "Quiz is coming soon! Stay tuned for updates."