import { domoMutations, domoQueries } from "./domoApis";
import { JourneyTemplateMutations, JourneyTemplateQueries } from "./journeyTemplateApis";

export const mutations = {
  ...domoMutations,
  ...JourneyTemplateMutations
}

export const queries = {
  ...domoQueries,
  ...JourneyTemplateQueries
}
