import {
  meetingTypes,
  minimumCountOfOptions,
  surveyQuestionTypes,
  TOUCH_POINT_TYPES,
} from "./constant";

export const validateTouchPoint = (touchPoint, isCompanyAdmin) => {
  let errors = {
    emailContent: {},
    hubContent: {},
  };

  if (touchPoint.touchPointName.trim() === "") {
    errors.touchPointName = "Touch point name is required";
  }
  if (touchPoint.touchPointDescription.trim() === "") {
    errors.touchPointDescription = "Touch point description is required";
  }

  if (touchPoint.emailContent.notificationViaEmail) {
    if (touchPoint.emailContent.design === "") {
      errors.emailContent.design = "Design is required";
    }
    if (touchPoint.emailContent.fromEmail === "" && isCompanyAdmin) {
      errors.emailContent.fromEmail = "From email is required";
    }
    if (touchPoint.emailContent.html === "") {
      errors.emailContent.html = "HTML is required";
    }
    if (touchPoint.emailContent.subject.trim() === "") {
      errors.emailContent.subject = "Subject is required";
    }
  }

  if (touchPoint.touchPointType !== TOUCH_POINT_TYPES.survey) {
    if (touchPoint.hubContent.hubTemplate.design === "") {
      errors.hubContent.design = "Design is required";
    }
    if (touchPoint.hubContent.hubTemplate.html === "") {
      errors.hubContent.html = "HTML is required";
    }
  }

  if (
    touchPoint.hubContent.minimumDaysToJoin === "" &&
    touchPoint.touchPointType !== TOUCH_POINT_TYPES.meeting
  ) {
    errors.hubContent.minimumDaysToJoin = "Minimum days to join is required";
  }
  if (touchPoint.hubContent.minimumDaysToJoin < 0) {
    errors.hubContent.minimumDaysToJoin =
      "Minimum days to join should be greater than or equal to 0";
  }
  if (touchPoint.touchPointType === TOUCH_POINT_TYPES.survey) {
    const error = validateSurveyTouchPoint(touchPoint);
    if (error !== undefined) {
      errors.hubContent.surveyValidation = error;
    }
  }
  if (touchPoint.touchPointType === TOUCH_POINT_TYPES.meeting) {
    const error = validateMeetingTouchPoint(touchPoint , isCompanyAdmin);
    if (error !== undefined) {
      errors.hubContent.meetingValidation = error;
    }
  }

  if (
    Object.keys(errors.emailContent).length === 0 &&
    Object.keys(errors.hubContent).length === 0 &&
    !errors.touchPointName &&
    !errors.touchPointDescription
  ) {
    return undefined;
  }

  return errors;
};

export const validateMeetingTouchPoint = (touchPoint , isCompanyAdmin) => {
  let errors = {};
  if (!touchPoint.hubContent.meetingPerson) {
    errors.meetingPerson = "Meeting person is required";
  }
  if (touchPoint.hubContent.meetingType === meetingTypes.inPerson) {
    if (!touchPoint.hubContent.meetingLocation && isCompanyAdmin) {
      errors.meetingLocation = "Meeting location is required";
    }
  }
  if (Object.keys(errors).length === 0) {
    return undefined;
  }
  return errors;
};

export const validateSurveyTouchPoint = (touchPoint) => {
  let errors = {};
  const regex = /<p>(\s|&nbsp;|<br>)*<\/p>/g;
  if (touchPoint?.hubContent?.questions?.length === 0) {
    errors.questionLength = "Survey questions are required";
  } else {
    const error = validateQuestions(touchPoint.hubContent.questions);
    const allErrorsUndefined = Object.values(error).every(
      (error) => error === undefined
    );
    if (!allErrorsUndefined) {
      errors.questions = error;
    }
  }
  if (!touchPoint.hubContent.headerDescription || regex.test(touchPoint.hubContent.headerDescription)) {
    errors.headerDescription = "Header description is required";
  }
  if (!touchPoint.hubContent.footerDescription || regex.test(touchPoint.hubContent.footerDescription)) {
    errors.footerDescription = "Footer description is required";
  }
  if (Object.keys(errors).length === 0) {
    return undefined;
  }
  return errors;
};

export const validateQuestions = (questions) => {
  let errors = [];
  questions.forEach((question, i) => {
    let error = {};
    if (question.text === "" || !question.text.replace(/\s/g, "")) {
      error.text = "Question is required";
    }
    if (question.type === "") {
      error.type = "Type is required";
    }
    if (
      [
        surveyQuestionTypes.singleSelect,
        surveyQuestionTypes.multiSelect,
      ].includes(question.type)
    ) {
      const selectError = validateSingleSelectQuestion(question);
      if (selectError) {
        error.question = selectError;
      }
    }
    if (Object.keys(error).length > 0) {
      errors.push(error);
    } else {
      errors.push(undefined);
    }
  });
  if (errors.length === 0) {
    return undefined;
  }
  return errors;
};

const validateSingleSelectQuestion = (question) => {
  const error = {};
  if (question.options.length < minimumCountOfOptions) {
    error.optionCount = "At least two options are required";
  }
  question.options.forEach((option, i) => {
    if (option.value === "" || !option.value.replace(/\s/g, "")) {
      error[`option${i}`] = "Option is required";
    }
  });
  if (Object.keys(error).length > 0) {
    return error;
  }
  return undefined;
};
